<template>
    <footer data-cy="footer-1" class="footer-1">
        <div data-cy="footer-1-banner" class="footer-banner">
            <a href="/"><img id="banner" src="../assets/logo/banner.svg" alt="Banner"></a>
        </div>
        <div class="footer-contact">
            <div class="address">
                <p>Cura Beteiligungen AG</p>
                <p>Kaisterstrasse 2</p>
                <p>5080, Laufenburg</p>
                <p>{{ $t('holding_country') }}</p>
            </div>
            <div class="contact">
                <p>info@cura-beteiligungen.ch</p>
                <p>+41628694114</p>
            </div>
        </div>
    </footer>
    <div class="spacer-4" style="display: flex;justify-content: center;">
        <hr style="width:80vw;height:2px;border-width:0;color:#a55252;background-color:#a55252;">
    </div>
    <!--<footer class="footer-2">
        <div class="footer-newsletter">
            <h2 id="newsletter-title" data-cy="footer-newsletter-title">{{ $t('footer_newsletter_title') }}</h2>
            <label class="newsletter-part email-field">
                <input id="newsletter-email" type="email" :placeholder="$t('footer_newsletter_email')">
                <button id="newsletter-submit">
                    ➟
                </button>
            </label>
            <label class="newsletter-part checkbox-text" data-cy="footer-newsletter-checkbox">
                <input id="newsletter-checkbox" type="checkbox">
                {{ $t('footer_newsletter_checkbox') }}
            </label>
        </div>
    </footer>-->
    <footer data-cy="footer-3" class="footer-3">
        <div data-cy="footer-3-logo" class="footer-logo">
            <img id="logo" src="../assets/logo/logo.png">
        </div>
        <nav data-cy="footer-3-legal" class="footer-legal footer-nav">
            <ul class="nav-list">
                <li class="nav-item">
                    <RouterLink class="link" to="/privacy-policy#top" data-cy="footer-legal-privacy-policy">{{
                        $t('footer_legal_privacy_policy') }} </RouterLink>
                </li>
                <li class="nav-item">
                    <RouterLink class="link" to="/imprint" data-cy="footer-legal-imprint">{{ 
                        $t('footer_legal_imprint') }} </RouterLink>
                </li>
                <li class="nav-item">
                    <RouterLink class="link" to="/credits" data-cy="footer-legal-credits">{{ 
                        $t('footer_legal_credits') }} </RouterLink>
                </li>
            </ul>
        </nav>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
    methods: {
        changeLanguage(locale) {
            this.$store.commit('setLocale', locale);
            this.$i18n.locale = locale;
        }
    }
}
</script>

<style scoped>
h2 {
    color: white;
}


.footer-1 {
    width: 100%;
    height: 20vh;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--footer-primary-color);
    user-select: none;
}

.footer-1>.footer-banner {
    margin-left: 15vw;
}

.footer-1>.footer-contact {
    display: flex;
    color: #a8a8a8;
    justify-content: center;
}

.footer-1>.footer-contact>.contact {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    justify-content: center;
}

.footer-1>.footer-contact>.contact>p {
    font-size: 12pt;
}

.footer-1>.footer-contact>.address {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    justify-content: center;
}

.footer-1>.footer-contact>.address>p {
    font-size: 12pt;
}


.footer-2 {
    width: 100%;
    height: 60vh;
    bottom: 0;
    display: grid;
    grid-template-columns: 75fr 25fr;
    background-color: #313131;
    align-items: center;
    user-select: none;
}

.footer-2>.footer-newsletter {
    grid-column: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-right: 50px;
}

.footer-2>.footer-newsletter>#newsletter-title {
    color: red;
}

.footer-2>.footer-newsletter>.newsletter-part {
    align-self: flex-start;
}

.footer-2>.footer-newsletter>.email-field {
    display: flex;
    align-items: center;
}

.footer-2>.footer-newsletter>.email-field>#newsletter-submit {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    margin-left: -58px;
    background-color: red;
    border: 0;
    color: white;
    font-size: 20pt;
}

#newsletter-submit:hover {
    cursor: pointer;
}

.footer-2>.footer-newsletter>.checkbox-text {
    margin-top: 30px;
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 13pt;
    color: white;
    display: flex;
    line-height: 1.5em;
}

.footer-2>.footer-newsletter>.newsletter-part>#newsletter-email {
    width: 300px;
    height: 20px;
    border-radius: 50px;
    border: none;
    background-color: #505050;
    padding: 20px;
    color: white;
    font-size: 20px;
}

#newsletter-email:focus {
    outline: max(2px, 1px) solid red;
    outline-offset: max(2px, 1px);
}

.footer-2>.footer-newsletter>.newsletter-part>#newsletter-checkbox {
    border: none;
    border-radius: 5px;
    min-width: 25px;
    max-width: 25px;
    width: 25px;
    height: 25px;
    margin-right: 20px;

    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    display: grid;
    place-content: center;
}

#newsletter-checkbox::before {
    content: "";
    width: 15px;
    height: 15px;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em red;
}

#newsletter-checkbox:checked::before {
    transform: scale(1);
}

#newsletter-checkbox:focus {
    outline: max(2px, 1px) solid white;
    outline-offset: max(2px, 1px);
}


.footer-3 {
    width: 100%;
    height: 20vh;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--footer-tertiary-color);
    user-select: none;
}

.footer-3>.footer-legal {
    grid-column: 1;
}

.footer-3>.footer-legal>.nav-list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-3>.footer-legal>.nav-list>.nav-item {
    float: left;
    margin-left: 40px;
}


.footer-nav>.nav-list {
    list-style: none;
    line-height: 2em;
}

.footer-nav>.nav-list>.nav-item>.link {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 16pt;
    text-decoration: none;
    color: var(--font-color);
    transition: 0.2s;
}

.footer-nav>.nav-list>.nav-item>.link:hover {
    color: red;
}

.spacer-4 {
    width: 100%;
    height: 2px;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: var(--footer-primary-color);
}


#banner {
    width: 400px;
}

#logo {
    width: 100px;
}


@media only screen and (max-width: 1050px) {
    #banner {
        width: 100%;
        max-width: 300px;
    }

    .footer-1 {
        display: flex;
        height: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-1>.footer-banner {
        margin-left: 0vw;
        margin-top: 20px;
    }

    .footer-1>.footer-contact {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .footer-1>.footer-contact>.contact>p {
        font-size: 8pt;
    }

    .footer-1>.footer-contact>.address>p {
        font-size: 8pt;
    }


    .footer-3 {
        height: 40vh;
        flex-direction: column;
    }

    .footer-3>.footer-legal>.nav-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0px;
    }

    .footer-3>.footer-legal>.nav-list>.nav-item {
        float: left;
        margin-left: 0px;
    }


    .footer-nav>.nav-list {
        list-style: none;
        line-height: 2em;
    }

    .footer-nav>.nav-list>.nav-item>.link {
        font-family: "indivisible-variable", sans-serif;
        font-variation-settings: "wght" 400;
        font-size: 16pt;
        text-decoration: none;
        color: var(--font-color);
        transition: 0.2s;
    }

    .footer-nav>.nav-list>.nav-item>.link:hover {
        color: red;
    }
}
</style>