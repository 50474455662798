<template>
  <div class="wrapper">
    <input type="checkbox" id="hide-checkbox" data-cy="themeswitch-checkbox" @click="switchTheme">
    <label for="hide-checkbox" class="toggle">
      <span class="toggle-button">
        <span class="crater crater-1"></span>
        <span class="crater crater-2"></span>
        <span class="crater crater-3"></span>
        <span class="crater crater-4"></span>
        <span class="crater crater-5"></span>
        <span class="crater crater-6"></span>
        <span class="crater crater-7"></span>
      </span>
      <span class="star star-1"></span>
      <span class="star star-2"></span>
      <span class="star star-3"></span>
      <span class="star star-4"></span>
      <span class="star star-5"></span>
      <span class="star star-6"></span>
      <span class="star star-7"></span>
      <span class="star star-8"></span>
    </label>
  </div>
</template>

<script>
export default {
  methods: {
    switchTheme() {
      themeSwitch();
    }
  },
  name: 'ThemeswitchComponent',
}

function themeSwitch() {
  if (document.getElementById("hide-checkbox").checked) {
    document.documentElement.setAttribute('theme', 'light');
    document.getElementById("banner-dark").style.display = 'none';
    document.getElementById("banner-light").style.display = 'block';
    document.getElementById("header-language").style.backgroundColor = '#ffeae2';
    document.getElementById("header-language").style.borderRadius = '15px';

    localStorage.setItem('theme', 'light');
  }
  else {
    document.documentElement.setAttribute('theme', 'dark');
    document.getElementById("banner-dark").style.display = 'block';
    document.getElementById("banner-light").style.display = 'none';
    document.getElementById("header-language").style.backgroundColor = '';
    document.getElementById("header-language").style.borderRadius = '';

    localStorage.setItem('theme', 'dark');
  }
}
</script>

<style scoped>
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #fffcc1;
}

* {
  box-sizing: inherit;
}

.wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 50px;
}

#hide-checkbox {
  opacity: 0;
  height: 0;
  width: 0;
}

.toggle {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 100px;
  height: 50px;
  background: #211042;
  border-radius: 25px;
  transition: 500ms;
  overflow: hidden;
}

.toggle-button {
  position: absolute;
  display: inline-block;
  top: 3.5px;
  left: 3px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background: #FAEAF1;
  overflow: hidden;
  box-shadow: 0 0 17.5px 2px rgba(255, 255, 255);
  transition: all 500ms ease-out;
}

.crater {
  position: absolute;
  display: inline-block;
  background: #FAEAF1;
  border-radius: 50%;
  transition: 500ms;
}

.crater-1 {
  background: #FFFFF9;
  width: 43px;
  height: 43px;
  left: 5px;
  bottom: 5px;
}

.crater-2 {
  width: 10px;
  height: 10px;
  top: -3.5px;
  left: 22px;
}

.crater-3 {
  width: 8px;
  height: 8px;
  top: 10px;
  right: -2px;
}

.crater-4 {
  width: 5px;
  height: 5px;
  top: 12px;
  left: 15px;
}

.crater-5 {
  width: 7.5px;
  height: 7.5px;
  top: 20px;
  left: 24px;
}

.crater-6 {
  width: 5px;
  height: 5px;
  top: 24px;
  left: 10px;
}

.crater-7 {
  width: 6px;
  height: 6px;
  bottom: 2.5px;
  left: 17.5px;
}

.star {
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0.5px 0 1px 1px rgba(255, 255, 255);
}

.star-1 {
  width: 3px;
  height: 3px;
  right: 45px;
  bottom: 20px;
}

.star-2 {
  width: 4px;
  height: 4px;
  right: 35px;
  top: 5px;
}

.star-3 {
  width: 2.5px;
  height: 2.5px;
  right: 30px;
  bottom: 7.5px;
}

.star-4 {
  width: 1.5px;
  height: 1.5px;
  right: 20px;
  bottom: 25px;
}

.star-5 {
  width: 2px;
  height: 2px;
  right: 5px;
}

.star-6,
.star-7,
.star-8 {
  width: 5px;
  height: 1px;
  border-radius: 1px;
  transform: rotate(-45deg);
  box-shadow: 2.5px 0px 2px 0.5px #FFF;
  animation-name: travel;
  animation-duration: 1.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}

.star-6 {
  right: 15px;
  bottom: 15px;
  animation-delay: -2s;
}

.star-7 {
  right: 25px;
  bottom: 30px;
}

.star-8 {
  right: 45px;
  top: 5px;
  animation-delay: -4s;
}

@keyframes travel {
  0% {
    transform: rotate(-45deg) translateX(35px);
  }

  50% {
    transform: rotate(-45deg) translateX(-10px);
    box-shadow: 2.5px 0px 3px 0.5px #FFF;
  }

  100% {
    transform: rotate(-45deg) translateX(-15px);
    width: 1px;
    height: 1px;
    opacity: 0;
    box-shadow: none;
  }
}

#hide-checkbox:checked+.toggle {
  background: #24D7F7;
}

#hide-checkbox:checked+.toggle .toggle-button {
  background: #F7FFFF;
  transform: translateX(47px);
  box-shadow: 0 0 17.5px 2.5px rgba(255, 255, 255);
}

#hide-checkbox:checked+.toggle .toggle-button .crater {
  transform: rotate(-45deg) translateX(35px);
}

#hide-checkbox:checked+.toggle .star {
  animation: move 2s infinite;
  transform: none;
  box-shadow: none;
}

#hide-checkbox:checked+.toggle .star-1 {
  width: 20px;
  height: 5px;
  border-radius: 5px;
  background: #FFF;
  left: 10px;
  top: 12.5px;
  box-shadow: none;
}

#hide-checkbox:checked+.toggle .star-2 {
  width: 6px;
  height: 6px;
  background: #FFF;
  left: 13px;
  top: 11.5px;
  box-shadow: -0.5px 0 1px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked+.toggle .star-3 {
  width: 8px;
  height: 8px;
  background: #FFF;
  left: 17.5px;
  top: 9.5px;
  box-shadow: -0.5px 0 1px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked+.toggle .star-4 {
  width: 7px;
  height: 7px;
  background: #FFF;
  left: 23px;
  top: 10px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked+.toggle .star-5 {
  width: 30px;
  height: 7.5px;
  border-radius: 15px;
  background: #FFF;
  left: 15px;
  bottom: 10px;
  box-shadow: none;
}

#hide-checkbox:checked+.toggle .star-6 {
  width: 9px;
  height: 9px;
  background: #FFF;
  border-radius: 50%;
  left: 19px;
  bottom: 10px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked+.toggle .star-7 {
  width: 12px;
  height: 12px;
  background: #FFF;
  border-radius: 50%;
  left: 26px;
  bottom: 10px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked+.toggle .star-8 {
  width: 10.5px;
  height: 10.5px;
  background: #FFF;
  border-radius: 50%;
  left: 35px;
  top: 29.5px;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.1);
}

@keyframes move {
  0% {
    transform: none;
  }

  25% {
    transform: translateX(0.75px);
  }

  100% {
    transform: translateX(-0.75px);
  }
}

p {
  text-align: center;
  letter-spacing: 7.5px;
  background: #34495e;
  color: #FFF;
}
</style>