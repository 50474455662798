import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import CreditsView from '../views/CreditsView.vue'

import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import ImprintView from '../views/ImprintView.vue'

import NotFound from '../components/404.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home - Cura Beteiligungen AG',
      breadcrumb: 'Home',
    },
  },
  {
    path: '/credits',
    name: 'credits',
    component: CreditsView,
    meta: {
      title: 'Credits - Cura Beteiligungen AG',
      breadcrumb: 'Credits',
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyView,
    meta: {
      title: 'Privacy Policy - Cura Beteiligungen AG',
      breadcrumb: 'Privacy Policy',
    },
    children: [
      
    ]
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: ImprintView,
    meta: {
      title: 'Imprint - Cura Beteiligungen AG',
      breadcrumb: 'Imprint',
    },
  },


  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      title: '404 Not Found',
      breadcrumb: '404',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: {
      title: '404 Not Found',
      breadcrumb: '404',
    },
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth'
      })
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll('[router-controlled]')).map(el => el.parentNode.removeChild(el));

  if(!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('router-controlled', '');
    return tag;
  })

  .forEach(tag => document.head.appendChild(tag));
  next();
});

export default router
