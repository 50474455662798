<template>
    <div class="container">
        <div class="background">
            <header data-cy="header" class="header">
                <div data-cy="header-banner" class="header-banner">
                    <a href="/"><img id="banner" src="../assets/logo/banner.svg" alt="Banner"></a>
                </div>
                <div data-cy="header-languages" class="header-language">
                    <button class="language-select" id="header-en" @click="changeLanguage('en')">
                        <img class="flag" src="../assets/flags/gb-eng.svg">
                    </button>
                    <a>/</a>
                    <button class="language-select" id="header-de" @click="changeLanguage('de')">
                        <img class="flag" src="../assets/flags/de.svg">
                    </button>
                    <a>/</a>
                    <button class="language-select" id="header-fr" @click="changeLanguage('fr')">
                        <img class="flag" src="../assets/flags/fr.svg">
                    </button>
                </div>
            </header>
            <div class="content">
                <h1 data-cy="404-title" id="title-1">{{ $t('notfound_title') }}</h1>
                <h2 data-cy="404-description" id="title-2">{{ $t('notfound_description') }}</h2>
                <RouterLink class="link" to="/" data-cy="404-link-home">{{ $t('notfound_link_home') }}</RouterLink>
            </div>
            <div class="objects">
                <img class="object-rocket" src="../assets/404/rocket.svg" width="40px">
                <div class="earth-moon">
                    <img class="object-earth" src="../assets/404/earth.svg" width="100px">
                    <img class="object-moon" src="../assets/404/moon.svg" width="80px">
                </div>
                <div class="box-astronaut">
                    <img class="object-astronaut" src="../assets/404/astronaut.svg" width="140px">
                </div>
            </div>
            <div class="glowing-stars">
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
                <div class="star"></div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import store from '../store'


export default {
    name: 'NotFound',
    components: {
        
    },
    methods: {
        changeLanguage(locale) {
            this.$store.commit('setLocale', locale);
            this.$i18n.locale = locale;

            languageNav();
        }
    }
}

function languageNav() {
    var headerNavitemLink1 = 'https://corabois.ch/de/';
    var headerNavitemLink2 = 'https://balteschwiler.ch/';
    var headerNavitemLink3 = 'https://alpsteg.ch/';

    if (store.state.locale == 'en') {
        headerNavitemLink1 = 'https://corabois.ch/en/';
        headerNavitemLink2 = 'https://balteschwiler.ch/';
        headerNavitemLink3 = 'https://alpsteg.ch/';
    } else if (store.state.locale == 'de') {
        headerNavitemLink1 = 'https://corabois.ch/de/';
        headerNavitemLink2 = 'https://balteschwiler.ch/';
        headerNavitemLink3 = 'https://alpsteg.ch/';
    } else if (store.state.locale == 'fr') {
        headerNavitemLink1 = 'https://corabois.ch/fr/';
        headerNavitemLink2 = 'https://balteschwiler.ch/fr/';
        headerNavitemLink3 = 'https://alpsteg.ch/';
    }

    if (
        (document.getElementById("link-1") != null) &&
        (document.getElementById("link-2") != null) &&
        (document.getElementById("link-3") != null)) {
        document.getElementById("link-1").setAttribute("href", headerNavitemLink1);
        document.getElementById("link-2").setAttribute("href", headerNavitemLink2);
        document.getElementById("link-3").setAttribute("href", headerNavitemLink3);
    }
}

$(document).ready(function () {
    languageNav();
});
</script>

<style scoped>
html,
body {
    user-select: none;
}

.header {
    height: 15vh;
    display: grid;
    grid: 25fr, 50fr, 25fr;
    background-color: transparent;
    user-select: none;
}

.header>.header-banner {
    display: flex;
    grid-column: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 50px;
}

#banner {
    width: 400px;
}

.header>.header-language {
    grid-column: 3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 105px;
}

.header>.header-language>a {
    color: white;
}

.header>.header-language>.language-select {
    font-family: "urbane", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 24pt;
    background-color: transparent;
    margin: 8px;
    border: none;
    cursor: default;
    color: white;
    transition: 0.2s;
}

.header-language>.language-select>.flag:hover {
    width: 36px;
    transition: 0.2s;
}


.flag {
    width: 32px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
}


.container {
    background: url('../assets/404/background.png');
    background-repeat: repeat-x;
    background-size: cover;
    background-position: left top;
    height: 100%;
    overflow: hidden;
}

.background {
    height: 100vh;
    position: relative;
    user-select: none;
    background: url('../assets/404/overlay_stars.svg');
    background-repeat: repeat;
    background-size: contain;
    background-position: left top;
}

.content {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 17% 5% 10% 5%;
    text-align: center;
    color: white;
}

#title-1,
#title-2 {
    text-align: center;
}

#title-1 {
    margin-top: 120px;
    font-size: 150pt;
}

#title-2 {
    font-size: 35pt;
    font-variation-settings: "wght" 200;
}

.link {
    position: relative;
    z-index: 200;
    margin: 15px auto;
    width: 350px;
    padding: 10px 15px;
    border: 1px solid #ff3939;
    border-radius: 150px;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;

    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 600;
    font-size: 14pt;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.link:hover {
    background-color: #ff3939;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
}





.objects img {
    z-index: 90;
    pointer-events: none;
}

.object-rocket {
    z-index: 95;
    position: absolute;
    transform: translateX(-50px);
    top: 75%;
    pointer-events: none;
    animation: rocket-movement 200s linear infinite both running;
}

.object-earth {
    position: absolute;
    top: 20%;
    left: 15%;
    z-index: 90;
}

.object-moon {
    position: absolute;
    top: 12%;
    left: 25%;
}

.object-astronaut {
    animation: rotate-astronaut 200s infinite linear both alternate;
}

.box-astronaut {
    z-index: 110 !important;
    position: absolute;
    top: 60%;
    right: 20%;
    will-change: transform;
    animation: move-astronaut 50s infinite linear both alternate;
}



.glowing-stars .star {
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    width: 3px;
    height: 3px;
    opacity: 0.3;
    will-change: opacity;
}

.glowing-stars .star:nth-child(1) {
    top: 80%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 1s;
}

.glowing-stars .star:nth-child(2) {
    top: 20%;
    left: 40%;
    animation: glow-star 2s infinite ease-in-out alternate 3s;
}

.glowing-stars .star:nth-child(3) {
    top: 25%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 5s;
}

.glowing-stars .star:nth-child(4) {
    top: 75%;
    left: 80%;
    animation: glow-star 2s infinite ease-in-out alternate 7s;
}

.glowing-stars .star:nth-child(5) {
    top: 90%;
    left: 50%;
    animation: glow-star 2s infinite ease-in-out alternate 9s;
}


@-moz-keyframes rocket-movement {
    100% {
        -moz-transform: translate(1200px, -600px);
    }
}

@-webkit-keyframes rocket-movement {
    100% {
        -webkit-transform: translate(1200px, -600px);
    }
}

@keyframes rocket-movement {
    100% {
        transform: translate(1200px, -600px);
    }
}

@-moz-keyframes spin-earth {
    100% {
        -moz-transform: rotate(-360deg);
        transition: transform 20s;
    }
}

@-webkit-keyframes spin-earth {
    100% {
        -webkit-transform: rotate(-360deg);
        transition: transform 20s;
    }
}

@keyframes spin-earth {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        transition: transform 20s;
    }
}

@-moz-keyframes move-astronaut {
    100% {
        -moz-transform: translate(-160px, -160px);
    }
}

@-webkit-keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
    }
}

@keyframes move-astronaut {
    100% {
        -webkit-transform: translate(-160px, -160px);
        transform: translate(-160px, -160px);
    }
}

@-moz-keyframes rotate-astronaut {
    100% {
        -moz-transform: rotate(-720deg);
    }
}

@-webkit-keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
    }
}

@keyframes rotate-astronaut {
    100% {
        -webkit-transform: rotate(-720deg);
        transform: rotate(-720deg);
    }
}

@-moz-keyframes glow-star {
    40% {
        -moz-opacity: 0.3;
    }

    90%,
    100% {
        -moz-opacity: 1;
        -moz-transform: scale(1.2);
    }
}

@-webkit-keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
    }

    90%,
    100% {
        -webkit-opacity: 1;
        -webkit-transform: scale(1.2);
    }
}

@keyframes glow-star {
    40% {
        -webkit-opacity: 0.3;
        opacity: 0.3;
    }

    90%,
    100% {
        -webkit-opacity: 1;
        opacity: 1;
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        border-radius: 999999px;
    }
}


@media only screen and (max-width: 600px) {
    .box-astronaut {
        top: 70%;
    }

    .content {
        padding-top: 25%;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 1;
    }

    .header>.header-language {
        margin-right: 0px;
    }

    #banner {
        width: 300px;
    }

    .header-language>.flag {
        align-self: center;
    }

    .header>.header-banner {
        margin-left: 0px;
        display: block;
    }
}
</style>