<template>
  <router-view/>
</template>

<style>
@import url("https://use.typekit.net/xio2kvs.css");


[theme="light"] {
    --font-color: black;
    --font-hover-color: #2e2e2e;

    --header-primary-color: #ffeee9;
    --header-secondary-color: #fff8f5;
    --header-secondary-banner-color: #ffe6e4;
    --header-secondary-banner-shadow-color: #b5b5b5;

    --home-branddisplay-color: #ffeae2;
    --home-showcase-color: #ffffff;

    --subpage-color: #ffffff;

    --footer-primary-color: #fffcfb;
    --footer-secondary-color: red;
    --footer-tertiary-color: #fff8f6;
}

[theme="dark"] {
    --font-color: white;
    --font-hover-color: #c4c4c4;

    --header-primary-color: #1a1d1e;
    --header-secondary-color: #25282a;
    --header-secondary-banner-color: #222527;
    --header-secondary-banner-shadow-color: #171a1b;

    --home-branddisplay-color: #272727;
    --home-showcase-color: #292929;

    --subpage-color: #2a2d2e;

    --footer-primary-color: #25282a;
    --footer-secondary-color: red;
    --footer-tertiary-color: #1a1d1e;
}

#app {
    background-color: var(--subpage-color);
    color: var(--font-color);
}

html {
    background-color: white;
}
body {
    padding: 0;
    margin: 0;
}

h1 {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 450;
    font-size: 45pt;
}
h2 {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 25pt;
}
h3 {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 18pt;
}
p {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 16pt;
}

.spacer-1 {
    height: 100px;
}
.spacer-2 {
    height: 250px;
}
.spacer-3 {
    height: 500px;
}

*::-webkit-scrollbar {
    width: 16px;
}
*::-webkit-scrollbar-track {
    border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #a3a3a3;
}
*::-webkit-scrollbar-thumb:hover {
    background-color: #727272;
}
</style>
