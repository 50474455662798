<template>
    <div class="root">
        <header data-cy="header-1" class="header-1">
            <nav data-cy="header-1-nav" class="header-nav">
                <ul>
                    <li>
                        <img class="brand-logo" src="../assets/logo/brands/corabois_logo.png">
                        <a data-cy="header-navitem-1" class="link" id="link-1" href="/" target="_blank">{{
                            $t('header_navitem_1') }}</a>
                    </li>
                    <li>
                        <img class="brand-logo" src="../assets/logo/brands/balteschwiler_logo.png">
                        <a data-cy="header-navitem-2" class="link" id="link-2" href="/" target="_blank">{{
                            $t('header_navitem_2') }}</a>
                    </li>
                    <li>
                        <img class="brand-logo" src="../assets/logo/brands/alpsteg_logo.png">
                        <a data-cy="header-navitem-3" class="link" id="link-3" href="/" target="_blank">{{
                            $t('header_navitem_3') }}</a>
                    </li>
                </ul>
            </nav>
        </header>
        <header data-cy="header-2" class="header-2">
            <div data-cy="header-2-banner" class="header-banner">
                <a href="/">
                    <img class="banner" id="banner-dark" style="display: none" src="../assets/logo/banner.svg" alt="Banner">
                    <img class="banner" id="banner-light" src="../assets/logo/banner_light.svg" alt="Banner">
                </a>
            </div>
            <div class="header-switches">
                <div class="header-theme" data-cy="header-2-themes" style="height: 1px;">
                    <label class="theme-switch">
                        <Themeswitch></Themeswitch>
                    </label>
                </div>
                <div data-cy="header-2-languages" class="header-language" id="header-language">
                    <button class="language-select" id="header-en" @click="changeLanguage('en')">
                        <img class="flag" src="../assets/flags/gb-eng.svg">
                    </button>
                    <a>/</a>
                    <button class="language-select" id="header-de" @click="changeLanguage('de')">
                        <img class="flag" src="../assets/flags/de.svg">
                    </button>
                    <a>/</a>
                    <button class="language-select" id="header-fr" @click="changeLanguage('fr')">
                        <img class="flag" src="../assets/flags/fr.svg">
                    </button>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import Themeswitch from '../components/Themeswitch.vue';

import $ from 'jquery';
import store from '../store';

export default {
    name: 'HeaderComponent',
    methods: {
        changeLanguage(locale) {
            this.$store.commit('setLocale', locale);
            this.$i18n.locale = locale;

            languageNav();
        }
    },
    components: {
        Themeswitch
    },
    mounted() {
        languageNav();
        themeSwitch();
    }
}

function themeSwitch() {
    const toggleSwitch = document.getElementById("hide-checkbox");
    const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light';

    if ((currentTheme) && (toggleSwitch != null)) {
        document.documentElement.setAttribute('theme', currentTheme);

        if (currentTheme === 'light') {
            toggleSwitch.checked = true;
            document.getElementById("header-language").style.backgroundColor = '#ffeae2';
            document.getElementById("header-language").style.borderRadius = '15px';
        } else {
            document.getElementById("banner-dark").style.display = 'block';
            document.getElementById("banner-light").style.display = 'none';
        }
    }

    function switchTheme(e) {
        if (e.target.checked) {
            document.documentElement.setAttribute('theme', 'light');
            document.getElementById("banner-dark").style.display = 'none';
            document.getElementById("banner-light").style.display = 'block';
            document.getElementById("header-language").style.backgroundColor = '#ffeae2';
            document.getElementById("header-language").style.borderRadius = '15px';

            localStorage.setItem('theme', 'light');
        }
        else {
            document.documentElement.setAttribute('theme', 'dark');
            document.getElementById("banner-dark").style.display = 'block';
            document.getElementById("banner-light").style.display = 'none';
            document.getElementById("header-language").style.backgroundColor = '';
            document.getElementById("header-language").style.borderRadius = '';

            localStorage.setItem('theme', 'dark');
        }
    }

    if (toggleSwitch != null) {
        toggleSwitch.addEventListener('change', switchTheme, false);
    }
}

function languageNav() {
    var headerNavitemLink1 = 'https://corabois.ch/de/';
    var headerNavitemLink2 = 'https://balteschwiler.ch/';
    var headerNavitemLink3 = 'https://alpsteg.ch/';

    if (store.state.locale == 'en') {
        headerNavitemLink1 = 'https://corabois.ch/en/';
        headerNavitemLink2 = 'https://balteschwiler.ch/';
        headerNavitemLink3 = 'https://alpsteg.ch/';
    } else if (store.state.locale == 'de') {
        headerNavitemLink1 = 'https://corabois.ch/de/';
        headerNavitemLink2 = 'https://balteschwiler.ch/';
        headerNavitemLink3 = 'https://alpsteg.ch/';
    } else if (store.state.locale == 'fr') {
        headerNavitemLink1 = 'https://corabois.ch/fr/';
        headerNavitemLink2 = 'https://balteschwiler.ch/fr/';
        headerNavitemLink3 = 'https://alpsteg.ch/';
    }

    if (
        (document.getElementById("link-1") != null) &&
        (document.getElementById("link-2") != null) &&
        (document.getElementById("link-3") != null)) {
        document.getElementById("link-1").setAttribute("href", headerNavitemLink1);
        document.getElementById("link-2").setAttribute("href", headerNavitemLink2);
        document.getElementById("link-3").setAttribute("href", headerNavitemLink3);
    }
}

$(document).ready(function () {
    languageNav();
    themeSwitch();
});

</script>

<style scoped>
.theme-switch {
    position: relative;
    margin-right: 50px;
}


.root {
    display: flex;
    max-height: 20vh;
    height: 20%;
    flex-direction: column;
}

.header-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--header-primary-color);
    user-select: none;
    order: 1;
    height: fit-content;
    min-height: 65px;
}


.header-1>.header-nav>ul {
    list-style: none;
    display: flex;
}

.header-1>.header-nav>ul>li>.link {
    font-family: urbane, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 16pt;
    color: var(--font-color);
    text-decoration: none;
    margin-right: 60px;
    transition: 0.1s;
}

.header-1>.header-nav>ul>li>.link:hover {
    color: #ff0000;
}

.brand-logo {
    width: 18px;
    margin-right: 10px;
}


.header-2 {
    height: fit-content;
    min-height: 15vh;
    display: grid;
    grid: 25fr, 50fr, 25fr;
    background-color: var(--header-secondary-color);
    user-select: none;
    order: 2;
}

.header-2>.header-banner {
    display: flex;
    grid-column: 1;
    align-items: center;
    justify-content: flex-start;
    margin-left: 50px;
}

.banner {
    width: 400px;
    transition: 0.5s;
}

.banner:hover {
    width: 410px;
    background-color: var(--header-secondary-banner-color);
    border-radius: 12px;
    box-shadow: -5px 7px var(--header-secondary-banner-shadow-color);
    border-color: aqua;
    transition: 0.5s;
}

.header-2>.header-switches {
    display: flex;
    grid-column: 3;
    align-items: center;
    justify-content: flex-end;
    margin-right: 105px;
}

.header-2>.header-switches>.header-theme {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.header-2>.header-switches>.header-language {
    display: flex;
    align-items: center;
}

.header-2>.header-switches>.header-language>a {
    color: white;
}

.header-2>.header-switches>.header-language>.language-select {
    font-family: "urbane", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 24pt;
    background-color: transparent;
    margin: 8px;
    border: none;
    cursor: default;
}

.header-2>.header-switches>.header-language>.language-select>.flag:hover {
    width: 36px;
    transition: 0.2s;
}


.flag {
    width: 32px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s;
}



@media only screen and (max-width: 1050px) {

    .header-1 {
        order: 2;
        min-height: inherit;
    }

    .header-nav>ul {
        width: 100%;
        gap: 0;
        flex-direction: column;
        text-align: center;
        transition: 0.2s;
        padding-inline-start: 0px;
    }

    .header-1>.header-nav>ul>li>.link {
        text-decoration: none;
        margin-right: 0px;
    }

    .header-2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 1;
        min-height: auto;
    }

    .header-2>.header-banner {
        margin-left: 0px;
        display: block;
    }

    .header-2>.header-switches {
        margin-right: 0px;
    }

    #banner {
        width: 300px;
    }

    .header-language>.header-switches>.flag {
        align-self: center;
    }

    .header-nav>ul {
        width: 101%;
        right: -1px;
        display: flex;
        flex-direction: column;
    }
}
</style>