import { createStore } from 'vuex'

const store = createStore({
  state: {
    locale: localStorage.getItem('locale') || 'en',
    theme: localStorage.getItem('theme') || 'light'
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem('locale', locale);
    },
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.setItem('theme', theme);
    }
  },
});

export default store;