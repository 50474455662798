<template>
	<Header />
	<div class="breadcrumbs">
		<Breadcrumbs />
	</div>
	<div class="home">
		<span class="home-content bright-text">
			<span class="home-title">
				<h1 class="cred-text" data-cy="home-welcome">Cura&nbsp;</h1>
				<h1 class="cwhite-text" data-cy="home-welcome">Beteiligungen&nbsp;</h1>
				<h1 class="cwhite-text" data-cy="home-welcome">AG</h1>
			</span>
			<h2 data-cy="home-greeting">{{ $t('home_holding_title') }}</h2>
		</span>
	</div>
	<div class="brand-display" data-cy="brand-display">
		<img id="start-brand" class="brands-logo" src="../assets/logo/brandshowcase/corabois.webp">
		<img class="brands-logo" src="../assets/logo/brandshowcase/balteschwiler.png">
		<img class="brands-logo" src="../assets/logo/brandshowcase/alpsteg.jpg">
	</div>
	<div class="showcase" data-cy="showcase">
		<div class="part">
			<img src="../assets/images/home-showcase-1.jpg">
			<p id="p-1" data-cy="home-showcase-1">{{ $t('home_showcase_1') }}</p>
		</div>
		<div class="part">
			<p id="p-2" data-cy="home-showcase-2">{{ $t('home_showcase_2') }}</p>
			<img src="../assets/images/home-showcase-2.jpg">
		</div>
		<div class="part">
			<img src="../assets/images/home-showcase-3.jpg">
			<p id="p-3" data-cy="home-showcase-3">{{ $t('home_showcase_3') }}</p>
		</div>
	</div>
	<div data-cy="cura-map" class="map-container">
		<l-map id="map" :options="{ attributionControl: false }" v-model="zoom" v-model:zoom="zoom"
			:center="[47.55842, 8.05779]">
			<l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
			<l-marker :icon="icon" :lat-lng="[47.55842, 8.05779]">
				<l-tooltip id="tooltip" :permanent="true">Cura Beteiligungen AG</l-tooltip>
			</l-marker>
		</l-map>
		<Map attributionControl={false} />
	</div>
	<Footer />
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue';

import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import { icon } from 'leaflet';
import "../assets/leaflet/style.css";



export default {
	name: 'HomeView',
	components: {
		Header,
		Footer,
		Breadcrumbs,

		LMap,
		LTileLayer,
		LMarker,
		LTooltip
	},

	data() {
		return {
			zoom: 17,
			icon: icon({
				iconUrl: require('../assets/leaflet/images/marker-icon-2x.png'),
				iconSize: [50, 82],
				iconAnchor: [25, 72]
			}),
			images: [
				'../assets/f/logo.png',
				'pfad/zum/bild2.jpg',
				'pfad/zum/bild3.jpg',
				'pfad/zum/bild4.jpg',
				'pfad/zum/bild5.jpg',
			],
			slides: [
				'First',
				'Second',
				'Third',
				'Fourth',
				'Fifth',
			],
		};
	},
}

</script>

<style scoped>

.dark-text {
	color: #272727;
}

.bright-text {
	color: white;
}

.cred-text {
	color: red;
}

.cwhite-text {
	color: white;
}

.breadcrumbs {
	display: none;
	margin-left: 8vw;
	margin-right: 8vw;
	margin-top: 5vh;
}

.home {
	background-image: url('../assets/background/home.jpg');
	background-size: cover;
	height: 60vh;
	user-select: none;
}

.home-content {
	display: flex;
	flex-direction: column;
	height: inherit;
	margin-left: 50px;
	margin-right: 50px;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.home-title {
	display: flex;
}

.brand-display {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20vh;
	background-color: var(--home-branddisplay-color);
}

.brand-display>#start-brand {
	margin-left: 0%;
}

.brand-display>.brands-logo {
	height: 10vh;
	margin-left: 5%;
}

.showcase {
	height: 300vh;
	background-color: var(--home-showcase-color);
}

.showcase>.part {
	height: 100vh;
	display: flex;
	margin-left: 10vw;
	margin-right: 10vw;
	padding-top: 10vh;
	padding-bottom: 10vh;
	align-items: center;
}

.showcase>.part>img {
	object-fit: cover;
	width: 40vw;
	height: 80vh;
	border-radius: 6px;
}

.showcase>.part>p {
	font-family: "veneer", sans-serif;
	font-weight: 400;
	font-style: normal;

	width: 40vw;
	font-size: 32pt;
}

.showcase>.part>#p-1 {
	background: -webkit-linear-gradient(#804d27, #b56d39);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	margin-left: 5vw;
}

.showcase>.part>#p-2 {
	background: -webkit-linear-gradient(#252020, #dc5c00);
	background-clip: text;
	-webkit-background-clip: text;

	-webkit-text-fill-color: transparent;
	margin-right: 5vw;
}

.showcase>.part>#p-3 {
	background: -webkit-linear-gradient(#482c0b, #7e6b58);
	background-clip: text;
	-webkit-background-clip: text;

	-webkit-text-fill-color: transparent;
	margin-left: 5vw;
}




*::-webkit-scrollbar {
	width: 0px;
}

.map-container {
	width: 100vw;
	height: 40vh;
	max-width: 100%;
}



@media only screen and (max-width: 1050px) {
	h1 {
		font-size: 32pt;
	}

	.breadcrumbs {
		margin-top: 18vh
	}

	.home {
		height: 80vh;
	}

	.home-content {
		margin-left: 20px;
		margin-right: 20px;
	}

	.home-title {
		flex-direction: column;
		line-height: normal;
	}


	.showcase>.part {
		margin-left: 5vw;
		margin-right: 5vw;
	}

	.showcase>.part>img {
		width: 90vw;
		filter: opacity(60%);
		-webkit-filter: opacity(60%);
	}

	.showcase>.part>p {
		position: absolute;
		width: 90vw;
	}

	.showcase>.part>#p-1 {
		margin-left: 0vw;
		text-align: center;
	}

	.showcase>.part>#p-2 {
		margin-right: 0vw;
		text-align: center;
	}

	.showcase>.part>#p-3 {
		margin-left: 0vw;
		text-align: center;
	}


	#credits>a {
		font-size: 16pt;
		text-decoration: none;
		color: gray;
	}
}
</style>