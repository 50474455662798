import App from './App.vue'

import { createApp } from 'vue'

import { createI18n } from 'vue-i18n'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import router from './router'

import store from "./store/index.js";

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})


const i18n = createI18n({
    locale: store.state.locale,
    messages: {
      en: require('../locales/en.json'),
      de: require('../locales/de.json'),
      fr: require('../locales/fr.json'),
    }
});

library.add(fas, fab)

createApp(App).use(store).use(router).use(i18n).use(vuetify).component('font-awesome-icon', FontAwesomeIcon).mount('#app')