<template>
    <Header />
    <div id="top"></div>
    <div class="breadcrumbs">
        <Breadcrumbs />
    </div>
    <div class="spacer-1"></div>
    <div class="container">
        <div class="title">
            <h1>{{ $t('privacypolicy_title') }}</h1>
            <br>
            <br>
        </div>

        <div class="section" id="s1">
            <p>Cura Beteiligungen AG</p>
            <p>Kaisterstrasse 2</p>
            <p>5080, Laufenburg</p>
            <p>{{ $t('holding_country') }}</p>
            <br>
        </div>
        <div class="section" id="s2">
            <h2>{{ $t('pp_s2_h1') }}</h2>
            <p>info@cura-beteiligungen.ch</p>
            <br>
        </div>
        <div class="section" id="s3">
            <h2>{{ $t('pp_s3_h1') }}</h2>
            <p>Vorname Nachname</p>
            <br>
        </div>
        <div class="section" id="s4">
            <h2>{{ $t('pp_s4_h1') }}</h2>
            <p>Cura Beteiligungen AG</p>
            <br>
        </div>
        <div class="section" id="s5">
            <h2>{{ $t('pp_s5_h1') }}</h2>
            <p>Vorname Nachname</p>
            <p>+41 59 1234556</p>
            <p>email@datenschuetzer.ch</p>
            <br>
        </div>
        <div class="section" id="s6">
            <h2>{{ $t('pp_s6_h1') }}</h2>
            <p>{{ $t('pp_s6_p1') }}</p>
            <p>{{ $t('pp_s6_p2') }}</p>
            <p>{{ $t('pp_s6_p3') }}</p>
            <p>{{ $t('pp_s6_p4') }}</p>
            <br>
        </div>
        <div class="section" id="s7">
            <h2>{{ $t('pp_s7_h1') }}</h2>
            <p>{{ $t('pp_s7_p1') }}</p>
            <p>{{ $t('pp_s7_p2') }}</p>
            <p>{{ $t('pp_s7_l1') }}</p>
            <p>{{ $t('pp_s7_l2') }}</p>
            <p>{{ $t('pp_s7_l3') }}</p>
            <p>{{ $t('pp_s7_l4') }}</p>
            <p>{{ $t('pp_s7_l5') }}</p>
            <p>{{ $t('pp_s7_p3') }}</p>
            <br>
        </div>
        <div class="section" id="s8">
            <h2>{{ $t('pp_s8_h1') }}</h2>
            <p>{{ $t('pp_s8_p1') }}</p>
            <p>{{ $t('pp_s8_p2') }}</p>
            <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>
            <br>
            <br>
            <a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>
            <br>
        </div>
        <div class="section" id="s9">
            <h2>{{ $t('pp_s9_h1') }}</h2>
            <p>{{ $t('pp_s9_p1') }}</p>
            <p>{{ $t('pp_s9_p2') }}</p>
            <br>
        </div>
        <div class="section" id="s10">
            <h2>{{ $t('pp_s10_h1') }}</h2>
            <p>{{ $t('pp_s10_p1') }}</p>
            <ul class="data-list">
                <li>{{ $t('pp_s10_l1') }}</li>
                <li>{{ $t('pp_s10_l2') }}</li>
                <li>{{ $t('pp_s10_l3') }}</li>
                <li>{{ $t('pp_s10_l4') }}</li>
                <li>{{ $t('pp_s10_l5') }}</li>
            </ul>
            <p>{{ $t('pp_s10_p3') }}</p>
            <br>
        </div>
        <div class="section" id="s11">
            <h2>{{ $t('pp_s11_h1') }}</h2>
            <p>{{ $t('pp_s11_p1') }}</p>
            <p>{{ $t('pp_s11_p2') }}</p>
            <p>{{ $t('pp_s11_p3') }}</p>
            <p>{{ $t('pp_s11_p4') }}</p>
            <br>
        </div>
        <div class="section" id="s12">
            <h2>{{ $t('pp_s12_h1') }}</h2>
            <p>{{ $t('pp_s12_p1') }}</p>
            <p>{{ $t('pp_s12_p2') }}</p>
            <p>{{ $t('pp_s12_p3') }}</p>
            <br>
        </div>
        <div class="section" id="s13">
            <h2>{{ $t('pp_s13_h1') }}</h2>
            <p>{{ $t('pp_s13_p1') }}</p>
            <a href="https://www.adobe.com/de/privacy/policy.html">https://www.adobe.com/de/privacy/policy.html</a>
            <br>
        </div>
        <div class="section" id="s14">
            <h2>{{ $t('pp_s14_h1') }}</h2>
            <p>{{ $t('pp_s14_p1') }}</p>
            <br>
        </div>
        <div class="section" id="s15">
            <h2>{{ $t('pp_s15_h1') }}</h2>
            <p>{{ $t('pp_s15_p1') }}</p>
            <p>{{ $t('pp_s15_p2') }}</p>
            <p>{{ $t('pp_s15_p3') }}</p>
            <br>
        </div>
        <div class="section" id="s16">
            <h2>{{ $t('pp_s16_h1') }}</h2>
            <a rel='nofollow' href='https://brainbox.swiss/'>
                BrainBox Solutions
            </a>
        </div>

        <div class="spacer-1"></div>

    </div>
    <Footer />
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Breadcrumbs from '../components/Breadcrumbs.vue'

export default {
    name: 'PrivacyPolicyView',
    components: {
        Header,
        Footer,
        Breadcrumbs
    }
}
</script>

<style scoped>
a {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 16pt;
    color: blue;
    text-decoration: none;
}

p {
    color: var(--font-color);
    line-height: 20pt;
}

.breadcrumbs {
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 5vh;
}

.container {
    margin-left: 10vw;
    margin-right: 10vw;
}

.section {
    text-align: justify;
}

.data-list {
    list-style: circle;
}

li {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 400;
    font-size: 16pt;
    line-height: 20pt;
    color: var(--font-color);
}


@media only screen and (max-width: 1050px) {
    .breadcrumbs {
        margin-top: 18vh
    }

    h1 {
        font-size: 22pt;
    }

    h2 {
        font-size: 18pt;
    }

    a {
        overflow-wrap: break-word;
    }

    .section {
        text-align: start;
    }
}
</style>