<template>
    <nav class="navigation">
        <router-link class="link" to="/">Home</router-link>
        <template v-for="(crumb) in breadcrumbs" :key="crumb">
            <span class="seperator">/</span>
            <router-link class="link" :to="crumb.to"> {{ crumb.label }} </router-link>
        </template>
    </nav>
</template>
  
<script>
    export default {
        name: 'BreadcrumbsComponent',
        computed: {
            breadcrumbs() {
            const breadcrumbs = [];
            const matchedRoutes = this.$route.matched;
  
            for (const route of matchedRoutes) {
                if (route.meta.breadcrumb) {
                    breadcrumbs.push({
                    label: route.meta.breadcrumb,
                    to: route.path,
                });
            }
        }
        return breadcrumbs;
      },
    }};
</script>
  
<style scoped>

.navigation {
    transition: 0.2s;
    border-bottom: 3px solid transparent;
    border-radius: 5px;
    width: fit-content;
    padding: 5px;
} .navigation:hover {
    border-color: #383838;
    border-radius: 5px;
}
.seperator {
    font-size: 18pt;
    margin-right: 12px;
    margin-left: 12px;
    color: #6b6b6b;
}
.link {
    font-family: "indivisible-variable", sans-serif;
    font-variation-settings: "wght" 300;
    font-size: 16pt;
    text-decoration: none;
    transition: 0.2s;
    color: inherit;
    border-bottom: 2px solid transparent;
    border-radius: 2px;
} .link:hover {
    transition: 0.2s;
    color: var(--font-hover-color);
    border-color: #d0d0d0;
    border-radius: 2px;
}

</style>  