<template>
    <Header />
    <div class="breadcrumbs">
        <Breadcrumbs />
    </div>

    <div class="spacer-1"></div>

    <div class="head">
        <div class="title">
            <h1>{{ $t('imprint_title') }}</h1>
            <br>
            <br>
        </div>
    </div>

    <div class="sections">
        <div class="section" id="s1">
            <p>Thomas Wächter</p>
            <p>info@cura-beteiligungen.ch</p>
            <p>+41628694114</p>
            <br>
        </div>
        <div class="section" id="s2">
            <p>Cura Beteiligungen AG</p>
            <p>Kaisterstrasse 2</p>
            <p>5080, Laufenburg</p>
            <p>{{ $t('holding_country') }}</p>
            <br>
        </div>
        <div class="section" id="s3">
            <p>CHE-102.706.293</p>
            <br>
        </div>
    </div>
    <Footer />
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Breadcrumbs from '../components/Breadcrumbs.vue'

export default {
    name: 'ImprintView',
    components: {
        Header,
        Footer,
        Breadcrumbs
    },
}

</script>

<style scoped>
.breadcrumbs {
    margin-left: 8vw;
    margin-right: 8vw;
    margin-top: 5vh;
}

.head {
    margin-left: 10vw;
    margin-right: 10vw;
}

.sections {
    margin-left: 20vw;
    margin-right: 20vw;
    display: flex;
    align-items: center;
}

.section {
    width: 20vw;
    text-align: start;
}


@media only screen and (max-width: 1050px) {
    .breadcrumbs {
        margin-top: 18vh
    }

    .sections {
        display: flex;
        flex-direction: column;
        margin-left: 10vw;
        margin-right: 10vw;
    }

    .section {
        width: 80vw;
    }
}
</style>